<template>
    <div>
        <download-csv v-if="export_json" style="display: inline-block;"
            :data   = "flatObjectToString(export_json)">
            <button type="button" class="btn btn-info ">Download data</button>
        </download-csv>
        <span class="" v-else>Getting export data</span>

        <json-viewer class="mt-5"
            :value="json_data"
            :expand-depth = "2"
        ></json-viewer>

        <pagination v-if="data" class="mt-3" 
            :data="data" @pagination-change-page="getData"
            :limit = 5
            align = "center"
            :show-disabled = "true"
            ></pagination>
    </div>
</template>

<script>

    export default {
        props: {
            type: String
        },
        data() {
            return {
                data: null,
                json_data: [],
                export_json: null
            }
        },
        methods: {
            getData(page = 1){
                axios.get('/api/get_json_data/' + this.type + '?page=' + page)
                    .then((response) => {
                        this.data = response.data;
                        this.json_data = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            },
            getExportData(){
                axios.get('/api/get_json_data/' + this.type + '/export')
                    .then((response) => {
                        this.export_json = response.data;
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            },
            flatObjectToString(obj) {
                var path = [],
                    nodes = {},
                    parseObj = function (obj) {
                        if (typeof obj == 'object') {
                            if (obj instanceof Array) { //array
                                for (var i = 0, l = obj.length; i < l; i++) {
                                    path.push(i);
                                    parseObj(obj[i]);
                                    path.pop();
                                }
                            }
                            else {  //object
                                for (var node in obj) {
                                    path.push(node);
                                    parseObj(obj[node]);
                                    path.pop();
                                }
                            }
                        }
                        else {  //value
                            nodes[path.join('|')] = obj;
                        }
                    };

                parseObj(obj);
                return Object.entries(nodes);
            },
        },
        mounted() {
            this.getData();
            this.getExportData();
        }
    }
</script>

<style scoped>

.card .card-header-success:not(.card-header-icon):not(.card-header-text) {
    background: #e5e5e5!important;
}

</style>

<template>
    <div class="card card-chart">
        <div class="card-header card-header-success">
            <div class="ct-chart">
                <apexchart height="300" type="line" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
        <div class="card-body">
            <h4 class="card-title">{{ title }}</h4>
           
        </div>
        
    </div>
</template>

<script>

    import VueApexCharts from 'vue-apexcharts'

    export default {
        props: {
            type: String,
            y_axis_text: String,
            title: String
        },
        data() {
            return {
                series: [],
                chartOptions: {
                    chart: {
                        id: 'creditsChart' + Math.random(),
                        height: 350,
                        type: 'bar',
                        dropShadow: {
                            enabled: false,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: ['#545454'],
                    tooltip: {
                        enabled: false,
                    },
                    dataLabels: {
                        enabled: true,
                        enabledOnSeries: [0],
                        background: {
                            // borderColor: '#000',
                            // foreColor: '#000',
                        },
                        style: {
                            // colors: '#000'
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: '',
                        align: 'left'
                    },
                    grid: {
                        borderColor: 'transparent',
                        row: {
                            colors: ['transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },
                    labels: [],
                    xaxis: {
                        title: {
                            text: 'Time'
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        labels: {
                            style: {
                                // colors: ['#fff'],
                            }
                        }
                    },
                    yaxis: [
                        {
                            title: {
                                text: this.y_axis_text
                            },
                        },
                    ],
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'right',
                    }
                },
            }
        },
        methods: {
            getData(){
                axios.get('/api/get_chart_data/' + this.type)
                    .then((response) => {
                        var id = this.chartOptions.chart.id;

                        var series = [];
                        response.data["series"].forEach(item => {
                            series.push(item);
                        });

                        ApexCharts.exec(id, "updateOptions", {
                            labels: response.data["labels"],
                            series: series,
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>
.tool-style .dropdown .dropdown-toggle{
    padding-right: 33px;
    border: 1px solid #000;
    min-width: 200px;
}

.tool-style .dropdown .dropdown-toggle>p{
    margin: 0px 7px 0px 0px;
    padding-right:7px;
    font-size: 23px;
    line-height: 40px;
    text-align: left;
    float: left;
}

.tool-style .dropdown .dropdown-menu{
    min-width: 200px;
}

.tool-style .dropdown .dropdown-menu a{
    margin: 0px 7px 0px 0px;
    padding-right:7px;
    float: left;
    font-size: 23px;
    line-height: 40px;
}

.tool-style .dropdown .dropdown-toggle::after{
    border-top: 7px dashed;
    border-top: 7px solid\9;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    margin-top: 15px;
}

.tool-style > span {
    color: #565656;
    float: left;
    font-family: Rubik;
    font-size: 34px;
    letter-spacing: 0.3px;
    width: 100%;
}

.tool-style p {
    padding-right: 80px;
    color: #565656;
    font-family: Rubik;
    font-size: 23px;
    line-height: 40px;
    margin: -10px 0 0;
    width: 100%;
}

.slider-blue {
  --slider-connect-bg: #3B82F6;
  --slider-tooltip-bg: #3B82F6;
  --slider-handle-ring-color: #3B82F630;
}

#map {
    width: 100%;
    height: 100%;
}

.marker-icon {
    margin-top: 13px;
    padding-left: 3px;
    padding-right: 3px;
}

#address-search-input {
    height: 50px !important;
    box-shadow: none !important;
    border: 1px solid #000;
    font-size: 23px;
}

.map-min-height{
    min-height: 600px;
}

#reticule {
    position:absolute;
    width:13px;
    height:13px;
    left:50%;
    top:50%;
    margin-top: -40px;
    margin-left: -20px;
    pointer-events: none;
}

#reticule>img {
    height: 40px;
}

.location {
    margin-bottom: 10px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    height: 40px;
}
.location:hover .location-number {
    background-color: #dfdfdf;
}
.location:hover .location-text {
    background-color: #dfdfdf;
}

.location-number {
    width: 60px;
    text-align: center;
    font-weight: bold;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #000;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
}

.location-text {
    text-align: center;
    font-weight: bold;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 10px;
    border: 1px solid #000;
    border-left: 0px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    margin-right: auto;
    width: 100%;

}

.location-buttons {
    height: 100%;
    align-items: center;
    display: flex;
}
.location-buttons>button {
    width: 70px;
    border: 1px solid #fff;
    border-radius: 4px;
}
.location-buttons>button.like {
    background-color: #baf9ba;
}
.location-buttons>button.dislike {
    background-color: #f9baba;
}
.location-buttons img {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    cursor: pointer;
}

</style>

<template>
    <div class="row">
        <div class="col-12">
            <img src="/template/images/adplaceholder.png" class="img-fluid mb-5 d-none" alt="Responsive image">
        </div>
        <div class="col-md-6 map-min-height mb-3">
            <div id="map"></div>
            <div v-if="show_reticule" id="reticule">
                <img src="/template/images/map_marker.png" />
            </div>
        </div>

        <div class="col-md-6">
            <template v-for="marker, index in markers">
                <div @mouseover="bounce(marker)" @mouseleave="stopBounce(marker)" @click="markerFocus(marker)" class="location">
                    <div class="location-number">
                        {{ index + 1 }}
                    </div>
                    <div class="location-text">
                        {{ marker.street }}, {{ marker.postcode }}
                    </div>
                    <div class="location-buttons">
                        <img class="like" @click.stop='listLike(marker)' src="/template/images/tool/like_button.png">
                        <img class="dislike" @click.stop='listDislike(marker)' src="/template/images/tool/dislike_button.png">
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
    import Slider from '@vueform/slider/dist/slider.vue2.js'
    import { v4 as uuidv4 } from 'uuid';

    export default {
        components: {
            Slider,
        },
        data() {
            return {
                errorBag: null,
                radius: 3000,
                interest_label: "Select area of interest",
                interest: null,
                interests: [],
                city: null,
                center: { lat: 51.509865, lng: -0.118092 },
                oldCenter: null,
                markers: [],
                map: null,
                centralPlace: null,
                session_id: null,
                results: [],
                locations: null,
                infoWindow: null,
                location_id: null,
                show_reticule: true,
                noSearch: false,
            }
        },
        methods: {
            capitalize(str) {
                if(typeof str === 'string') {
                    return str.replace(/^\w/, c => c.toUpperCase());
                } else {
                    return '';
                }
            },
            getInterests() {
                axios.get('/api/interests')
                .then((response) => {
                    this.interests = response.data;
                });
            },
            setInterest(string, loadData = true) {
                this.interest_label = this.capitalize(string);
                this.interest = string;
                if (loadData) {
                    this.getMarkers(10);
                }
            },
            setCity(string) {
                this.city = string;
                if (string == "london"){
                    this.setCenter(51.509865, -0.118092);
                } else if (string == "singapore") {
                    this.setCenter(1.28967, 103.85007);
                } else if (string == "melbourne"){
                    this.setCenter(-37.814, 144.96332);
                }
            },
            getMarkers(results = 10) {
                if (this.noSearch) {
                    return;
                }

                this.errorBag = null;
                if (!this.interest || !this.city) {
                    this.errorBag = {
                        interest: !this.interest,
                        city: !this.city,
                        centralPlace: !this.centralPlace
                    };
                    return
                }

                // this.setupMap(10 + 10/((this.radius / 1000) + 1));
                for (var i = 0; i < this.markers.length; i++ ) {
                    this.markers[i].setMap(null);
                }
                this.markers = [];
                if (this.interest && this.city){
                    axios.get('/api/locations', {
                        params: {
                            city: this.city,
                            interest: this.interest,
                            distance: this.radius,
                            c_latitude: this.center.lat,
                            c_longitude: this.center.lng,
                            results: results,
                        }
                    })
                    .then((response) => {

                        if (this.infoWindow){
                            this.infoWindow.close();
                        }

                        this.locations = response.data;

                        this.markers = [];
                        this.locations.forEach((location, index) => {
                            const markerLocation = {
                                lat: Number(location.lat),
                                lng: Number(location.lng),
                            };
                            var marker = new google.maps.Marker({
                                position: markerLocation,
                                map: this.map,
                                icon: '/template/images/order/number_' + (index+1) + '.png',
                            });

                            marker.metadata = "test";
                            marker.street = location.street;
                            marker.postcode = location.postcode;
                            marker.lat = Number(location.lat);
                            marker.lng = Number(location.lng);

                            google.maps.event.addListener(marker, 'click', function(_marker) {
                                this.getMarkerInfo(_marker, location.street, location.postcode);
                                this.show_reticule = false;
                            }.bind(this));
                            google.maps.event.addListener(marker, 'mouseover', function(_marker) {
                                this.setMarkerTitle(marker, location.street);
                            }.bind(this));

                            this.markers.push(marker);
                        });
                        // this.setCenter(this.center.lat, this.center.lng)

                        this.mapSearch();
                    });
                }
            },
            setCenter(lat, lng) {
                this.center = { lat: Number(lat), lng: Number(lng) };
                this.map.setCenter(this.center);
            },
            setURLparameters() {
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                let city = params.get("city");
                if (city) {
                    this.setCity(city);
                }

                let interest = params.get("interest");
                if (interest) {
                    this.setInterest(interest, false);
                }
            },
            setMarkerTitle(marker, street) {
                if (!marker.title){
                    var location = marker.position;
                    var radius = 50;
                    var request = {
                        location: location,
                        type: [this.interest],
                        radius: radius,
                    };
                    let map = this.map
                    var service = new google.maps.places.PlacesService(this.map);

                    service.nearbySearch(request, function(results, status){
                        // marker.title = "There are " + results.length + " places you would enjoy here.";
                        marker.setTitle("There are " + results.length + " places you would enjoy here.");
                    }.bind(this));
                }
            },
            getMarkerInfo(marker, street, postcode) {
                var location = marker.latLng;
                var radius = 50;
                var request = {
                    location: location,
                    type: [this.interest],
                    radius: radius,
                };
                let map = this.map
                var service = new google.maps.places.PlacesService(this.map);

                service.nearbySearch(request, function(results, status){
                    this.results = [];
                    let info = "<h6 class='marker-info-title'>" + street + ", " + postcode +  "</h6>";
                    var marker_id = uuidv4();

                    info += "<div style='position: absolute; bottom: 0px; width: 90%; background-color: #fff;'>"
                    info += "<h6 class='mt-3' style='display:inline-block; margin-top: 13px;'>#: " + results.length + "</h6>"
                    info += "<i id='dislike-" + marker_id + "' class='fa fa-thumbs-down float-right' style='margin-top: 13px; padding-left: 3px; padding-right: 3px;' aria-hidden='true' onMouseOver='this.style.cursor=\"pointer\"'></i>"
                    info += "<i id='like-" + marker_id + "' class='fa fa-thumbs-up float-right' style='margin-top: 13px; padding-left: 3px; padding-right: 3px; color: #3490dc;' aria-hidden='true' onMouseOver='this.style.cursor=\"pointer\"'></i>"
                    info += "</div>"

                    this.markerEvent(location, radius, street, postcode, "marker_click");

                    if (this.infoWindow){
                        this.infoWindow.close();
                    }

                    var infoWindow = new google.maps.InfoWindow({
                        content: info,
                    });

                    this.infoWindow = infoWindow;

                    infoWindow.addListener("domready", function(){
                        this.setMarkerEvents(marker_id, location, radius, street, postcode, marker);
                    }.bind(this));

                    infoWindow.setPosition(location);
                    infoWindow.open(map);
                }.bind(this));
            },
            setMarkerEvents(marker_id, location, radius, street, postcode, marker){
                // set like/dislike events
                document.getElementById("dislike-" + marker_id).addEventListener("click", function() {
                    this.markerEvent(location, radius, street, postcode, "marker_rating", marker, marker_id, -1);
                }.bind(this));

                document.getElementById("like-" + marker_id).addEventListener("click", function() {
                    this.markerEvent(location, radius, street, postcode, "marker_rating", marker, marker_id, 1);
                }.bind(this));
            },
            markerEvent(location, radius, street, postcode, type, marker, marker_id, rating_value){

                if (type == "marker_rating"){
                    this.locations.forEach(location => {
                        if (location.street == street){
                            this.location_id = location.id;
                        }
                    });

                    if (this.infoWindow && rating_value == -1){
                        this.infoWindow.close();
                    }
                }

                // save map event
                axios.post('/api/event_colector', {
                    type: type,
                    location: location,
                    street: street,
                    postcode: postcode,
                    city: this.city,
                    interest: this.interest,
                    radius: radius,
                    session_id: this.session_id,
                    results: this.results,
                    location_id: this.location_id,
                    rating_value: rating_value,
                })
                .then((response) => {
                    if (rating_value === -1){
                        if (this.map.zoom == 14) {
                            this.getMarkers();
                        } else {
                            this.map.setZoom(14);
                        }
                    }
                    if (rating_value === 1){
                        var element = document.getElementById("like-" + marker_id);
                        element.classList.remove("like-animate");
                        void element.offsetWidth;
                        element.classList.add("like-animate");
                    }
                    if (response.status != 200){
                        console.log(response);
                    }
                });
            },
            mapSearch(){
                // save map event on street click
                axios.post('/api/event_colector', {
                    city: this.city,
                    interest: this.interest,
                    distance: this.radius,
                    center: {
                        lat: this.center.lat, 
                        lng: this.center.lng
                    },
                    locations: this.locations,
                    session_id: this.session_id,
                    type: "search",
                })
                .then((response) => {
                    if (response.status != 200){
                        console.log(response);
                    }
                });
            },
            setupMap(zoom = 14){
                var myStyles =[
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ];
                this.map = new window.google.maps.Map(document.getElementById("map"), {
                    center: this.center,
                    zoom: zoom,
                    disableDefaultUI: true,
                    styles:myStyles
                });

                this.map.addListener("dragend", () => {
                    this.center = {
                        lat: this.map.getCenter().lat(),
                        lng: this.map.getCenter().lng(),
                    }
                    if (!this.noSearch) {
                        this.oldCenter = this.center;
                    }

                    this.getMarkers(10);
                });

                this.map.addListener("dragstart", () => {
                    this.show_reticule = true;
                    if (this.infoWindow){
                        this.infoWindow.close();
                    }
                });

                this.map.addListener("zoom_changed", () => {
                    this.show_reticule = true;
                    
                    this.center = {
                        lat: this.map.getCenter().lat(),
                        lng: this.map.getCenter().lng(),
                    }
                    if (!this.noSearch) {
                        this.oldCenter = this.center;
                    }
                    
                    this.setRadius(this.map.getZoom());
                    this.getMarkers(10);
                });
            },
            setRadius(zoom) {
                if (this.noSearch) {
                    return;
                }

                switch (zoom) {
                    case (zoom = 10):
                        this.radius = 5000;
                        break;
                    case (zoom = 11):
                        this.radius = 4000;
                        break;
                    case (zoom = 12):
                        this.radius = 3000;
                        break;
                    case 13:
                        this.radius = 2200;
                        break;
                    case 14:
                        this.radius = 1500;
                        break;
                    case 15:
                        this.radius = 1000;
                        break;
                    case 16:
                        this.radius = 500;
                        break;
                }
            },
            bounce(marker) {
                marker.setAnimation(google.maps.Animation.BOUNCE);
            },
            stopBounce(marker) {
                marker.setAnimation(null);
            },
            markerFocus(marker) {
                if (!this.oldCenter) {
                    this.oldCenter = this.center;
                }

                this.setCenter(marker.lat, marker.lng);

                this.noSearch = true;
                this.map.setZoom(16);
                this.noSearch = false;

                this.show_reticule = false;
            },
            listDislike(marker) {
                if (this.oldCenter) {
                    this.setCenter(this.oldCenter.lat, this.oldCenter.lng);
                }
                this.markerEvent(
                    {
                        lat: marker.lat,
                        lng: marker.lng,
                    }, 
                    this.radius, 
                    marker.street, 
                    marker.postcode, 
                    "marker_rating", 
                    marker, 
                    null, 
                    -1
                );
                this.$toastr.s('Disliked');
            },
            listLike(marker) {
                this.markerEvent(
                    {
                        lat: marker.lat,
                        lng: marker.lng,
                    }, 
                    this.radius, 
                    marker.street, 
                    marker.postcode, 
                    "marker_rating", 
                    marker, 
                    null, 
                    1
                );
                this.$toastr.s('Liked');
            }
        },
        mounted() {
            this.session_id = $cookies.get("WHT-sid");
            this.getInterests();
            this.setupMap();
            this.setURLparameters();

            this.getMarkers(10);
        }
    }
</script>

<template>
    <div class="card">
        <div :class="'card-header ' + color_class">
            <h4 class="card-title">{{ title }}</h4>
            <p class="card-category">{{ description }}</p>
        </div>
        <div class="card-body table-responsive">
            <table class="table table-hover">
                <thead class="text-info">
                    <th>City</th>
                    <th>Interest</th>
                    <th>Street</th>
                    <th>Postcode</th>
                    <th>Likes</th>
                </thead>
                <tbody>
                    <tr v-for="item in data">
                        <td>{{ item.city }}</td>
                        <td>{{ item.interest }}</td>
                        <td>{{ item.street }}</td>
                        <td>{{ item.postcode }}</td>
                        <td>{{ item.count }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: String,
            type: String,
            description: String,
            color_class: String,
        },
        data() {
            return {
                data: [],
            }
        },
        methods: {
            getData(){
                axios.get('/api/get_list_data/' + this.type)
                    .then((response) => {
                        this.data = response.data["data"];
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>

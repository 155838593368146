<template>
    <div class="card card-stats">
        <div :class="'card-header card-header-icon ' + color_class">
            <div class="card-icon">
                <i class="material-icons">{{icon}}</i>
            </div>
            <p class="card-category">{{title}}</p>
            <h3 class="card-title">
                {{value}}
            </h3>
        </div>
        <div class="card-footer">
            <div class="stats">
                {{description}}
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: String,
            type: String,
            description: String,
            icon: String,
            color_class: String,
        },
        data() {
            return {
                value: "-",
            }
        },
        methods: {
            getData(){
                axios.get('/api/get_widget_data/' + this.type)
                    .then((response) => {
                        this.value = response.data["data"];
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>

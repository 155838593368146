<template>
<div>
    <div class="block">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="step == 1" class="client-sec">
                        <div class="inner-header">
                            <h1>Which city do you want to experience?</h1>
                        </div>
                        <div class="row w-100">
                            <div @click="stepOne('melbourne')" class="col-md-4">
                                <div class="client step-one">
                                    <img src="template/images/cities/melbourne.webp" alt="" />
                                </div>
                                <h3 class="client-hover-title">Melbourne</h3>
                            </div>
                            <div @click="stepOne('singapore')" class="col-md-4">
                                <div class="client step-one">
                                    <img src="template/images/cities/singapore-croped.webp" alt="" />
                                </div>
                                <h3 class="client-hover-title">Singapore</h3>
                            </div>
                            <div @click="stepOne('london')" class="col-md-4">
                                <div class="client step-one">
                                    <img src="template/images/cities/london.webp" alt="" />
                                </div>
                                <h3 class="client-hover-title">London</h3>
                            </div>
                        </div>
                        <div class="row w-100 mt-5" style="justify-content: center;">
                            <button class="btn btn-primary request-btn" data-toggle="modal" data-target="#suggestCityModal">Request new city</button>
                        </div>
                    </div>

                    <div v-if="step == 2" class="client-sec">
                        <div class="inner-header">
                            <h1>How would you like the start of your experience to feel?</h1>
                        </div>
                        <div class="row w-100">
                            <div @click="stepTwo('A')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/A.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepTwo('B')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/B.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepTwo('C')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/C.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepTwo('D')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/D.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepTwo('E')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/E.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepTwo('F')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/F.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 mt-5" style="justify-content: center;">
                            <button @click="step = 1" class="btn btn-primary request-btn">Back</button>
                        </div>
                    </div>

                    <div v-if="step == 3" class="client-sec">
                        <div class="inner-header">
                            <h1>How would you like the end of your experience to feel?</h1>
                        </div>
                        <div class="row w-100">
                            <div @click="stepThree('1')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/1.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepThree('2')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/2.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepThree('3')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/3.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepThree('4')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/4.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepThree('5')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/5.webp" alt="" />
                                </div>
                            </div>

                            <div @click="stepThree('6')" class="col-md-4">
                                <div class="client step-two">
                                    <img src="template/images/feels/6.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 mt-5" style="justify-content: center;">
                            <button @click="step = 2" class="btn btn-primary request-btn">Back</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Suggest city modal -->
    <div class="modal fade" id="suggestCityModal" tabindex="-1" role="dialog" aria-labelledby="suggestCityModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="coming-sson-sec mb-4">
                        <div class="logo">
                            <img src="/template/images/logo/whereto_black_on_white_logo_square.webp">
                        </div>
                        <p class="mb-5">Request a new city.</p>
                        <template v-if="suggestCitySuccess">
                            <div class="alert alert-success" role="alert">
                                <strong>Request sent</strong>
                            </div>
                        </template>
                        <template v-else>
                            <div id="suggestCity">
                                <div class="contact-form">
                                    <form method="post" action="contact.php" name="contactform" id="contactform">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <i class="fa fa-globe" aria-hidden="true"></i>
                                                <input v-model="formCity" name="city" type="text" id="city" placeholder="City">
                                            </div>
                                            <div class="col-md-12">
                                                <i class="fa fa-at"></i>
                                                <input v-model="email" name="email" type="text" id="email" placeholder="Email">
                                            </div>
                                            <div class="col-md-12 submit-column">
                                                <button @click="sendCitySuggestion()" class="submit theme-btn" >REQUEST NOW</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <template v-if="suggestCityError">
                                        <div class="alert alert-danger" role="alert">
                                            <strong>{{suggestCityError}}</strong>
                                        </div>
                                    </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

    export default {
        props: {
        },
        data() {
            return {
                step: 1,
                city: "",
                feelStart: "",
                feelEnd: "",
                city: "",
                email: "",
                formCity: "",
                suggestCityError: false,
                suggestCitySuccess: false
            }
        },
        methods: {
            stepOne(city){
                this.city = city;
                this.step = 2;
            },
            stepTwo(feel){
                this.feelStart = feel;
                this.step = 3;
            },
            stepThree(feel){
                this.feelEnd = feel;
                window.location = "/tool?interest=" + this.feelStart + this.feelEnd + "&city=" + this.city;
            },
            sendCitySuggestion() {
                this.suggestCityError = null;
                axios
                .post('/suggest_city', {
                    email: this.email,
                    city: this.formCity,
                })
                .then((response) => {
                    if (response.status == 200){
                        this.suggestCitySuccess = true;
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        var errors = error.response.data.errors;
                        this.suggestCityError = errors[Object.keys(errors)[0]][0];
                    }
                }.bind(this));
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
    .step-one img {
        height: 450px;
        max-width: 300px;
        object-fit: cover;
        cursor: pointer;
    }

    .step-two img {
        height: 300px;
        max-width: 300px;
        object-fit: cover;
        cursor: pointer;
    }

    .request-btn {
        background-color: #000;
    }
    .request-btn:active {
        background-color: #000!important;
    }

    #suggestCity {
        margin: auto;
        max-width: 350px;
        display: inline-block;
    }
    #suggestCity .submit-column {
        display: flex;
    }
    #suggestCity .submit-column .submit.theme-btn {
        max-width: 350px;
        width: 100%;
        margin: auto;
    }
    #suggestCityModal .alert {
        margin: auto;
        max-width: 350px;
    }
</style>
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'
import { v4 as uuidv4 } from 'uuid';
import VueApexCharts from 'vue-apexcharts'
import JsonViewer from 'vue-json-viewer'
import JsonCSV from 'vue-json-csv'
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue';
import swal from 'sweetalert2'
import VueToastr from 'vue-toastr'

window.swalert = (confirmText, titleText, type, confirmButtonColor, success, dismiss = null, text = null) => {
    return swal.fire({
        text: text,
        title: titleText,
        confirmButtonColor: confirmButtonColor,
        showCancelButton: true,
        customClass: 'sweetalert-background',
        confirmButtonText: confirmText,
    }).then(result => {
        if (result.value) {
            success();
        } else {
            if (dismiss) {
                dismiss();
                return;
            }
        }
    });
};

// set user cookie
if (!$cookies.get("WHT-sid")){
    var uuid = uuidv4();
    $cookies.set("WHT-sid", uuid, "30d");

    // save session start event
    axios.post('/api/event_colector', {
        session_id: uuid,
        type: "session_start",
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
    })
    .then((response) => {
        if (response.status != 200){
            console.log(response);
        }
    });
}

window.eventHub = new Vue;

Vue.use(
    VueCompositionAPI,
    VueCookies,
)
Vue.use(JsonViewer)
Vue.use(VueToastr, {
    defaultPosition: 'toast-top-right',
    defaultTimeout: 3000
});


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('tool', require('./components/Tool.vue').default);
Vue.component('widget', require('./components/Widget.vue').default);
Vue.component('chart', require('./components/Chart.vue').default);
Vue.component('list', require('./components/List.vue').default);
Vue.component('select-options', require('./components/Select.vue').default);
Vue.component('json-block', require('./components/JsonBlock.vue').default);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('downloadCsv', JsonCSV)
Vue.component('vuetable', Vuetable)
Vue.component('locations', require('./components/Locations.vue').default);
Vue.component('cities', require('./components/cities.vue').default);
Vue.component('apexchart', VueApexCharts)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

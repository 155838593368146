<template>
    <div>
        <vuetable ref="vuetable"
            api-url="/api/get_table_data/cities"
            :fields="fields"
            data-path="data"
            pagination-path=""
            pagination-component="vuetable-pagination"
            @vuetable:pagination-data="onPaginationData" 
            :append-params="moreParams"
            id="locations-table"
        >
        </vuetable>

        <div class="vuetable-pagination ml-1">
            <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info"/>
            <vuetable-pagination
                ref="pagination"
                :css="paginationClasses"
                @vuetable-pagination:change-page="onChangePage"/>
        </div>
    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        props: {
            
        },
        data() {
            return {
                filterText: '',
                moreParams: {},
                paginationClasses: {
                    wrapperClass: 'pagination',
                    activeClass: 'active',
                    disabledClass: 'disabled',
                    pageClass: 'page-link',
                    linkClass: 'page-link',
                    dropdownClass: '',
                    icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-chevron-left',
                        next: 'fa fa-chevron-right',
                        last: 'fa fa-step-forward',
                    },
                },
                fields: [
                    {
                        name: 'city',
                        sortField: 'city'
                    },
                    {
                        name: 'total',
                        sortField: 'total'
                    },
                ],
            }
        },
        methods: {
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page)
            }
            
        },
        mounted() {
            /* Reload table on event */
            eventHub.$on('reload-table', () => {
                this.$refs.vuetable.reload()
            });
        }
    }
</script>

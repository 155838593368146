<template>
    <div>

        <div v-if="status" class="row">
            <div class="col-sm-12">
                <div :class="'alert ' + status_class">
                    <button @click="deleteStatus()" type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="material-icons" style="font-size: 20px;">close</i>
                    </button>
                    <span>{{status}}</span>
                </div>
            </div>
        </div>

        <div id="datatable-buttons_filter" class="dataTables_filter float-right">
            <label>
                <input type="search" class="form-control form-control-sm float-right" v-model="filterText" placeholder="Search" aria-controls="datatable-buttons" @keyup="onFilterSet(filterText)">
            </label>
        </div>

        <vuetable ref="vuetable"
            api-url="/api/get_table_data/locations"
            :fields="fields"
            data-path="data"
            pagination-path=""
            pagination-component="vuetable-pagination"
            @vuetable:pagination-data="onPaginationData" 
            :append-params="moreParams"
            id="locations-table"
        >

            <template slot="actions" slot-scope="props">
                <div class="custom-actions">
                    <button
                        style="margin-right: 4px"
                        :id="props.rowData.id"
                        :class="['btn', 'btn-xs']"
                        @click="editItem(props.rowData)"
                    >
                        <span class="material-icons">edit</span>
                    </button>

                    <button
                        style="margin-right: 4px"
                        :id="props.rowData.id"
                        :class="['btn', 'btn-xs']"
                        @click="deleteItem(props.rowData)"
                    >
                        <span class="material-icons">delete</span>
                    </button>
                </div>
            </template>
        </vuetable>

        <div class="vuetable-pagination ml-1">
            <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info"/>
            <vuetable-pagination
                ref="pagination"
                :css="paginationClasses"
                @vuetable-pagination:change-page="onChangePage"/>
        </div>
    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        props: {
            
        },
        data() {
            return {
                filterText: '',
                moreParams: {},
                paginationClasses: {
                    wrapperClass: 'pagination',
                    activeClass: 'active',
                    disabledClass: 'disabled',
                    pageClass: 'page-link',
                    linkClass: 'page-link',
                    dropdownClass: '',
                    icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-chevron-left',
                        next: 'fa fa-chevron-right',
                        last: 'fa fa-step-forward',
                    },
                },
                fields: [
                    {
                        name: 'city',
                        sortField: 'city'
                    },
                    {
                        name: 'type',
                        sortField: 'type'
                    },
                    {
                        name: 'lat',
                        sortField: 'lat'
                    },
                    {
                        name: 'lng',
                        sortField: 'lng'
                    },
                    {
                        name: 'postcode',
                        sortField: 'postcode'
                    },
                    {
                        name: 'street',
                        sortField: 'street'
                    },
                    {
                        name: 'score',
                        sortField: 'score'
                    },
                    {
                        name: 'fullname',
                        sortField: 'fullname'
                    },
                    {
                        name: 'likes',
                        sortField: 'likes'
                    },
                    {
                        name: 'dislikes',
                        sortField: 'dislikes'
                    },
                    {
                        name: 'actions'
                    }
                ],
                actions: [
                    {
                        label: 'Profile',
                        buttonClass: 'btn-primary',
                        method: 'editUser',
                        form: 'editForm',
                    },
                ],
                status: null,
                status_class: "alert-secondary",
            }
        },
        methods: {
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page)
            },
            /* Set filter and reload table */
            onFilterSet (ftext) {
                Vue.set(this.moreParams, 'filter', ftext ? ftext : this.filterText);

                Vue.nextTick( () => this.$refs.vuetable.refresh());
            },
            /* Delete item */
            deleteItem(rowData) {
                let success = () => {
                    axios.delete('/api/location/delete/' + rowData.id)
                        .then( (response) => {
                            eventHub.$emit('reload-table');
                            this.$toastr.s('success', 'Location Deleted.');
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
                };

                swalert('Confirm', 'Delete location?', 'warning', '#f05050', success);
            },
            // edit location
            editItem(rowData) {
                window.location.href = '/admin/locations/edit/' + rowData.id;
            },
            getStatus(){
                axios.get('/api/location/get_status/')
                        .then( (response) => {
                            this.status = response.data.status;
                            if (this.status == "Import in progress") {
                                this.status_class = "alert-secondary";
                            } else if (this.status == "Import finished"){
                                this.status_class = "alert-success";
                            } else {
                                this.status_class = "alert-danger";
                            }
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            },
            deleteStatus(){
                axios.delete('/api/location/delete_status/')
                        .then( (response) => {
                            this.status = null;
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            },
        },
        mounted() {
            /* Reload table on event */
            eventHub.$on('reload-table', () => {
                this.$refs.vuetable.reload()
            });

            window.setInterval(() => {
                this.getStatus()
            }, 5000)
        }
    }
</script>
